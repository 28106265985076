import React from 'react'
import { navigate } from 'gatsby'
import _ from 'lodash'
import styled from 'styled-components'

import { getSearchParams } from '../../utils/utils'
import FormList from '../../components/Forms/FormsMasterList'

const ContactStyles = styled.div`
  .top-section {
    margin-bottom: 20px;
  }
`

const navToFormPage = e => {
  const { value } = e.target

  if (value) {
    navigate(`?form=${value}`, {
      replace: true,
    })
  }
}

export default function MissionStatement({ location }) {
  const formToShow = _.get(getSearchParams({ location }), ['form'], undefined)
  let FormDisplay

  if (formToShow && FormList[formToShow]) {
    FormDisplay = FormList[formToShow]
  }

  return (
    <ContactStyles className="content-width">
      <div className="title">
        <h1 className="title">Contact</h1>
      </div>
      <div className="top-section">
        <p>
          Choose contact option from the selection below. You will be required
          to fill out a form for submission.
        </p>
        <select name="formType" onChange={navToFormPage}>
          <option value="">Select Contact Form</option>
          <option value="ContactPres">Contact District President</option>
          <option value="ReportDeceased">Report Deceased Member</option>
          <option value="WebsiteIssue">Report Website Issue</option>
        </select>
      </div>
      {/* <ul>
          <li>
            <Link to="?form=ContactPres" rel="canonical">
              Contact District President
            </Link>
          </li>
          <li>
            <Link to="?form=ReportDeceased" rel="canonical">
              Report Deceased Member
            </Link>
          </li>
          <li>
            <Link to="?form=WebsiteIssue" rel="canonical">
              Report Website Issue
            </Link>
          </li>
        </ul> */}
      <div>{FormDisplay && <FormDisplay />}</div>
    </ContactStyles>
  )
}
