import { first } from 'lodash'

export const formatPhone = input => {
  const cleaned = `${input}`.replace(/\D/g, '')

  const firstSet = cleaned.slice(0, 3)
  const secondSet = cleaned.slice(3, 6)
  const thirdSet = cleaned.slice(6, 10)

  let str = ''

  if (firstSet) {
    str += firstSet.length === 3 ? `(${firstSet}) ` : `(${firstSet} `
  }

  if (secondSet) {
    str += secondSet
  }

  if (thirdSet) {
    str += `-${thirdSet}`
  }

  return str
}

const memberId = input => {
  const cleaned = `${input}`.replace(/\D/g, '')
  return cleaned
}

export default function ValidateAndFormat(event) {
  const { target } = event
  const { name } = target
  let { value } = target

  const { formatType } = target.dataset

  const format = {
    phone: formatPhone,
    memberId,
  }

  if (formatType) {
    value = format[formatType](value)
  }

  return {
    target,
    name,
    value,
  }
}
