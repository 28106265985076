import React, { Component } from 'react'
import styled from 'styled-components'
import FormStyles from '../../styles/FormStyles'

export default class ContactPresForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  }

  componentDidMount() {}

  handleSubmit = event => {
    event.preventDefault()
    alert(this.state)
  }

  handleInputChange = event => {
    const { target } = event
    const { value } = target
    const { name } = target

    this.setState({
      [name]: value,
    })
  }

  render() {
    const { name, email, subject, message } = this.state

    return (
      <FormStyles>
        <form
          method="POST"
          action="https://formspree.io/f/mgeppdnq"
          id="contact-pres"
          className="item-wrapper"
        >
          <h1 className="title">Contact District President</h1>

          <div className="section end">
            <div className="half">
              <label htmlFor="name">
                Your Name:
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={name}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="half">
              <label htmlFor="email">
                Your Email:
                <input
                  type="email"
                  name="email"
                  placeholder="E-Mail"
                  value={email}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="full">
              <label htmlFor="subject">
                Subject
                <input
                  type="text"
                  name="subject"
                  placeholder="Subject"
                  value={subject}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="full">
              <label htmlFor="subject">
                Message
                <textarea
                  name="message"
                  placeholder="Message to the President"
                  value={message}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>

            <div className="full right">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </FormStyles>
    )
  }
}
