import React, { Component } from 'react'
import validateAndFormat from './utils'
import FormStyles from '../../styles/FormStyles'

export default class ContactPresForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      relationship: '',
      deceasedName: '',
      deceasedMemberId: '', // optional
      dateOfDeath: new Date(), // optional
      nextOfKinName: '',
      nextOfKinEmail: '',
      nextOfKinPhone: '',
      otherNotes: '',
    }
  }

  componentDidMount() {}

  handleSubmit = event => {
    event.preventDefault()
    alert(this.state)
  }

  handleInputChange = event => {
    const { name, value } = validateAndFormat(event)

    this.setState({
      [name]: value,
    })
  }

  render() {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSf74Dl5t5-Sb6wfRzb-V1WBVSHKb9oaq-zxFSV625uoQFQZSA/viewform',
      '_blank',
      {
        rel: 'noopener noreferrer',
      }
    )

    return (
      <>
        <h1 className="title">Report Deceased Member</h1>
        <p>
          Please go to our google form page{' '}
          <a
            className="dark"
            href="https://docs.google.com/forms/d/e/1FAIpQLSf74Dl5t5-Sb6wfRzb-V1WBVSHKb9oaq-zxFSV625uoQFQZSA/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            HERE
          </a>{' '}
          to submit information about a deceased member.
        </p>
      </>
    )

    // const {
    //   name,
    //   email,
    //   phone,
    //   relationship,
    //   deceasedName,
    //   deceasedMemberId,
    //   dateOfDeath,
    //   nextOfKinName,
    //   nextOfKinEmail,
    //   nextOfKinPhone,
    //   otherNotes,
    // } = this.state

    // return (
    //   <FormStyles>
    //     <form
    //       method="POST"
    //       action="https://formspree.io/f/meqpnvep"
    //       id="report-deceased"
    //       className="item-wrapper"
    //     >
    //       <h1 className="title">Report Deceased Member</h1>
    //       <div className="section">
    //         <div className="header">
    //           <h2 className="section-title">Your Information:</h2>
    //         </div>

    //         <div className="half">
    //           <label htmlFor="name">
    //             Your Name:
    //             <input
    //               type="text"
    //               name="name"
    //               placeholder="Your Name"
    //               value={name}
    //               onChange={e => this.handleInputChange(e)}
    //               required
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="email">
    //             Your Email:
    //             <input
    //               type="email"
    //               name="email"
    //               placeholder="E-Mail"
    //               value={email}
    //               onChange={e => this.handleInputChange(e)}
    //               required
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="phone">
    //             Your Number:
    //             <input
    //               name="phone"
    //               placeholder="Your Phone Number"
    //               value={phone}
    //               data-format-type="phone"
    //               onChange={e => this.handleInputChange(e)}
    //               required
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="relationship">
    //             Your Relationship With Deceased:
    //             <input
    //               type="text"
    //               name="relationship"
    //               placeholder="Relationship with Deceased"
    //               value={relationship}
    //               onChange={e => this.handleInputChange(e)}
    //               required
    //             />
    //           </label>
    //         </div>
    //       </div>

    //       <div className="section">
    //         <div className="header">
    //           <h2 className="section-title">Passed Member Information:</h2>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="deceasedName">
    //             Name of Deceased:
    //             <input
    //               name="deceasedName"
    //               placeholder="Name of Deceased"
    //               value={deceasedName}
    //               onChange={e => this.handleInputChange(e)}
    //               required
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="deceasedMemberId">
    //             Member ID of Deceased:
    //             <input
    //               name="deceasedMemberId"
    //               placeholder="Member ID of Deceased"
    //               value={deceasedMemberId}
    //               data-format-type="memberId"
    //               onChange={e => this.handleInputChange(e)}
    //               required
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="dateOfDeath">
    //             Date of Death: (if known)
    //             <input
    //               name="dateOfDeath"
    //               type="date"
    //               value={dateOfDeath}
    //               onChange={e => this.handleInputChange(e)}
    //             />
    //           </label>
    //         </div>
    //       </div>

    //       <div className="section">
    //         <div className="header">
    //           <h2 className="section-title">Next of kin Information:</h2>
    //           <p className="description">
    //             Fill out only if you are not the next of kin.
    //           </p>
    //         </div>

    //         <div className="half">
    //           <label htmlFor="nextOfKinName">
    //             Name - Next of Kin:
    //             <input
    //               name="nextOfKinName"
    //               placeholder="Name of Next of Kin"
    //               value={nextOfKinName}
    //               onChange={e => this.handleInputChange(e)}
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="nextOfKinEmail">
    //             Email - Next of Kin:
    //             <input
    //               name="nextOfKinEmail"
    //               type="email"
    //               placeholder="Email of Next of Kin"
    //               value={nextOfKinEmail}
    //               onChange={e => this.handleInputChange(e)}
    //             />
    //           </label>
    //         </div>
    //         <div className="half">
    //           <label htmlFor="nextOfKinPhone">
    //             Phone - Next of Kin:
    //             <input
    //               name="nextOfKinPhone"
    //               placeholder="Name of Next of Kin"
    //               value={nextOfKinPhone}
    //               data-format-type="phone"
    //               onChange={e => this.handleInputChange(e)}
    //             />
    //           </label>
    //         </div>
    //       </div>
    //       <div className="section end">
    //         <div className="full">
    //           <label htmlFor="otherNotes">
    //             Other Notes:
    //             <textarea
    //               name="otherNotes"
    //               placeholder="Any other notes you feel should be mentioned."
    //               value={otherNotes}
    //               onChange={e => this.handleInputChange(e)}
    //             />
    //           </label>
    //         </div>
    //         <div className="full right">
    //           <button type="submit">Submit</button>
    //         </div>
    //       </div>
    //     </form>
    //   </FormStyles>
    // )
  }
}
