import React, { Component } from 'react'
import validateAndFormat from './utils'
import FormStyles from '../../styles/FormStyles'

export default class ContactPresForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      pageUrl: 'www.pioneerdistrict.org/',
      deviceType: '',
      browserType: '',
      supportingFiles: [],
      otherNotes: '',
    }
  }

  componentDidMount() {}

  handleSubmit = event => {
    event.preventDefault()
    alert(this.state)
  }

  handleInputChange = event => {
    const { name, value } = validateAndFormat(event)

    this.setState({
      [name]: value,
    })
  }

  render() {
    const {
      name,
      email,
      phone,
      pageUrl,
      otherNotes,
      deviceType,
      browserType,
    } = this.state

    return (
      <FormStyles>
        <form
          method="POST"
          action="https://formspree.io/f/xpzobkpn"
          id="website-issue"
          className="item-wrapper"
        >
          <h1 className="title">Report Website Issue</h1>
          <h4>
            Currently, our forms are unable to accept file uploads. If you have
            an image showcasing your issue, please email{' '}
            <a className="dark" href="mailto:chris@cbateson.com">
              chris@cbateson.com
            </a>{' '}
            with the subject "Reporting Website Issue".
          </h4>
          <div className="section">
            <div className="header">
              <h2 className="section-title">Your Information:</h2>
            </div>

            <div className="half">
              <label htmlFor="name">
                Your Name:
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={name}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="half">
              <label htmlFor="email">
                Your Email:
                <input
                  type="email"
                  name="email"
                  placeholder="E-Mail"
                  value={email}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="half">
              <label htmlFor="phone">
                Your Number:
                <input
                  name="phone"
                  placeholder="Your Phone Number"
                  value={phone}
                  data-format-type="phone"
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
          </div>

          <div className="section">
            <div className="header">
              <h2 className="section-title">Issue Information</h2>
            </div>
            <div className="full">
              <label htmlFor="pageUrl">
                Page URL:
                <input
                  name="pageUrl"
                  value={pageUrl}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="half">
              <label htmlFor="deviceType">
                What Device are you using?
                <input
                  name="deviceType"
                  placeholder="iPhone, PC, Mac, Android, iPad...?"
                  value={deviceType}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
            <div className="half">
              <label htmlFor="browserType">
                What Browser are you using?
                <input
                  name="browserType"
                  placeholder="IE, Edge, Safari, Chrome, Firefox...?"
                  value={browserType}
                  onChange={e => this.handleInputChange(e)}
                  required
                />
              </label>
            </div>
          </div>

          <div className="section end">
            <div className="full">
              <label htmlFor="otherNotes">
                Other Notes:
                <textarea
                  name="otherNotes"
                  placeholder="Any other notes you feel should be mentioned."
                  value={otherNotes}
                  onChange={e => this.handleInputChange(e)}
                />
              </label>
            </div>
            <div className="full right">
              <button type="submit">Submit</button>
            </div>
          </div>
        </form>
      </FormStyles>
    )
  }
}
